import { toast } from "react-toastify";
import { login, signOut } from "../services/identity.service";
import { getProfile } from "../services/player.service";
import {
	CLEAR_USER_PROFILE,
	LOGIN_FAIL,
	LOGIN_SUCCESS,
	LOGOUT,
	UPDATE_USER_PROFILE,
} from "./types";

export const loginAction =
	(data: any, t: any, providerData) => (dispatch: any) => {
		return login(data).then(
			(result) => {
				if (!result.status) {
					dispatch({
						type: LOGIN_FAIL,
					});
					toast.error(result.message);
					return Promise.reject(false);
				}
				// If login success
				const gameUrl = result.data.gameURL;
				const userToken = result.data.player_token;
				const rememberMe = localStorage.getItem("rememberMe");
				if (rememberMe === "true") {
					localStorage.setItem("user_token", userToken);
				} else {
					sessionStorage.setItem("user_token", userToken);
				}

				localStorage.setItem("language", result.data.player_language);
				// Proceed to get current user profile
				getProfile().then(
					(profile) => {
						// if (profile.status) {
						dispatch({
							type: LOGIN_SUCCESS,
							payload: {
								isLoggedIn: true,
								userProfile: profile.data,
								token: userToken,
							},
						});
						dispatch({
							type: UPDATE_USER_PROFILE,
							payload: {
								userProfile: profile.data,
							},
						});

						if (providerData.provider_type === "LOBBY") {
							window.location.href = "/lobby";
							sessionStorage.setItem("provider_type", 'LOBBY');
						} else if (providerData.provider_type === "WEBSITE") {
							window.open(gameUrl, "_blank");
							// store into session storage type is website
							sessionStorage.setItem("provider_type", 'WEBSITE');
						}
						return Promise.resolve();
					},
					(error) => {
						dispatch({
							type: LOGIN_FAIL,
						});
						toast.error(t("Action Failed. Please try again later."));
						console.log(error);
						return Promise.reject(false);
					}
				);
			},
			(error) => {
				dispatch({
					type: LOGIN_FAIL,
				});
				toast.error(t("Action Failed. Please try again later."));
				console.log(error);
				return Promise.reject(false);
			}
		);
	};

export const logoutAction = () => (dispatch: any) => {
	try {
		signOut();
		sessionStorage.clear();
		localStorage.clear();
		dispatch({ type: LOGOUT });
		dispatch({ type: CLEAR_USER_PROFILE });
		toast.success("Logout successfully");
		window.location.href = "/";
	} catch (error) {
		console.error(error);
	}
};
